import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './archives.module.css'
import SEO from '../../components/seo'
import Layout from '../../components/layout/Layout'
import {ArchiveList} from '../../components/archive-list/archive-list'

export default class Archives extends React.Component {
  render() {
    const { data } = this.props
    const articles = data.allMarkdownRemark.edges.map((e) => e.node)
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout>
        <article className={styles.page}>
          <SEO title={`Archives | ${siteTitle}`} />
          <h1>Blog Archive</h1>
          <ArchiveList articles={articles} />
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { eq: false } }
        fields: { isBlogArticle: { eq: true } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            categories
            description
          }
          timeToRead
        }
      }
    }
  }
`
